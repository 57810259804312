<template>
	<div id="main">
		<h1>Thank you for upgrading!</h1>
		<p>
			You will receive an email once your new & improved design is ready.
		</p>
		<p>
			View your brand kit here:
			<a
				v-text="orderId"
				@click="$router.push(`/brand-kit/${orderId}`)"
			></a>
		</p>
		<p>
			View your original order here:
			<a v-text="orderId" @click="$router.push(`/order/${orderId}`)"></a>
		</p>
	</div>
</template>

<script>
	import { fireGet, firePut, fireAsyncSignIn } from '../../db';

	export default {
		name: 'BrandKitThankYou',
		props: {
			orderId: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				data: {}
			};
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					fireGet(this.orderId).then(data => {
						if (data) {
							this.data = data;
							const routeId =
								localStorage.getItem('brandKitToUpgrade');
							firePut(this.orderId, {
								...data,
								...{
									selectedBrandKit: routeId
								}
							});
						} else {
							this.data = {};
						}
					});
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		}
	};
</script>

<style scoped>
	#main {
		max-width: 90%;
		margin: 0 auto;
		padding-top: 15vh;
	}

	h1,
	p,
	a {
		color: var(--black);
		text-align: center;
	}

	h1 {
		font-size: 3rem;
	}

	a {
		color: var(--text);
		text-decoration: underline;
	}

	a:hover {
		cursor: pointer;
		color: var(--accent-blue);
	}
</style>
